// Gray scale
$whiteColor: #FFFFFF;
$whiteSmokeColor: #F3F3F3;
$offWhiteColor: #FFFEFE;
$medOffWhiteColor: #F3F2F3;
$darkOffWhiteColor: #F8F8FA;
$lightestGrayColor: #D8D8D8;
$lightGrayColor: #D6D4D4;
$darkerLightGrayColor: #E0E0E0;
$darkLightGrayColor: #E5E5E5;
$medLightGrayColor: #C6C6C6;
$medGrayColor: #9BA4AA;
$medDarkGrayColor: #888888;
$lightDarkGrayColor: #666666;
$darkGrayColor: #424242;
$lightBlack: #151516;
$medBlackColor: #201F1F;
$blackColor: #000000;

$redColor: #FF0000;
$lightRedColor: #EB5757;

$proxVeryLightBlue: #b7c7de;
$proxLightBlue: #708fbe;
$proxBlue: #104492;
