$header-height: 71px;

.gray-button {
  color: $blackColor;
  background: $whiteColor;
  border: solid 1px $lightGrayColor;
  text-transform: uppercase;
}

.blue-button {
  color: $whiteColor;
  background: $proxBlue;
  border: solid 1px $proxBlue;
  text-transform: uppercase;
}

.red-button {
  color: $whiteColor;
  background: $lightRedColor;
  border: solid 1px $lightRedColor;
  text-transform: uppercase;
}