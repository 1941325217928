@import "./app/styles/colors.scss";
@import "./app/styles/fonts.scss";
@import "./app/styles/layouts.scss";

/* You can add global styles to this file, and also import other style files */
html, body { height: 100%; }

* {
  font-family: $bodyFont;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $bodyFont;
}

.page-container {
  padding: 20px;
  background-color: $lightGrayColor;
}

#app-header{
  .p-toolbar {
    background-color: $proxVeryLightBlue;
    background-image: url('./assets/proxrcdl-logo.png');
    background-size: 200px;
    background-repeat: no-repeat;
    background-position-y: center;
    background-origin: content-box;
  }
}

cdl-login-page {

  .login-fields, .change-fields {
    p-password {
      width: 100%;
      
      .p-password {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}

cdl-users-page {

  #add-new > button {
    margin-bottom: 15px;
  }
}

.data-list-panel > .p-scrollpanel-wrapper > .p-scrollpanel-content {
  background-color: $darkerLightGrayColor;
  padding: 20px;
}

.cdl-new-user-content {

  .p-dialog-header {
    padding: 1.5rem 1.5rem 0 1.5rem;

    .p-dialog-title {
      color: $proxBlue;
      font-weight: normal;
      font-size: 34px;
      line-height: 37px;  
    }
  }

  .p-dialog-content {
    padding: 0 1.5rem;

    .new-user-fields {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .p-field {
        margin-bottom: 15px;

        input {
          width: 100%;
        }

        label {
          font-size: 12px;
        }

      }
    }
  }

  .p-dialog-footer {
    padding: 0 0 1.5rem 0;
  }
}


cdl-new-user-dialog {

  .p-dialog-content {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  #role {
    width: 100%;

    > div {
      width: 100%;
    }
  }

  #corporation > span,
  #campus > span,
  #dealership > span {
    width: 100%;
    
    > input {
      width: 100%;
    }
  }
}

.power-bi-report-container {
  height: 100%;
}

#p-customer-table,
#p-importreport-table {
  .p-datatable-header {
    display: flex;
    justify-content: end;

    #clear-filters {
      font-family: $headerFont;
      font-size: 12px;
      text-decoration: none;
      margin: auto 0;
      padding-right: 10px;
      font-weight: 400;
    }
  }

  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    font-size: 14px;
    padding: 0.5rem 0;
  }

  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    font-size: 14px;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#p-importreport-table {
  .p-datatable-header {
    display: flex;
    justify-content: start;

    div {
      margin-left: 15px;
    }
  }
}
